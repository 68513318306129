import React, { useState, Fragment, useEffect } from "react";
import BreadCrumb from "../../layout/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap";
import ScrollArea from "react-scrollbar";

const Samplepage = (props) => {
  const segmentos = require("../../data/UNSPC_segmento.json");
  const familias = require("../../data/UNSPC_familias.json");
  const clases = require("../../data/UNSPC_class.json");

  const [filteredSegmentos, setFilteredSegmentos] = useState([]);
  useEffect(() => {
    filterSegmento("PRODUCTOS");
  }, []);

  const filterSegmento = (type) => {
    if (type == "PRODUCTOS") {
      setFilteredSegmentos(
        segmentos.filter((item) => !item.esp.includes("SERVICIOS"))
      );
    } else {
      setFilteredSegmentos(
        segmentos.filter((item) => item.esp.includes("SERVICIOS"))
      );
    }
  };

  const [currentSegmento, setCurrentSegmento] = useState(null);
  const [currentFamilia, setCurrentFamilia] = useState(null);

  const [subFamilias, setSubFamilias] = useState([]);
  const [subClases, setSubClases] = useState([]);

  const [selectedClases, setSelectedClases] = useState([]);

  const setSegmento = (i, code) => {
    setCurrentSegmento(i);
    if (i == currentSegmento) {
      setCurrentSegmento(null);
    }
    setSubFamilias(
      familias.filter(
        (familia) =>
          familia.code.toString().substring(0, 2) ==
          code.toString().substring(0, 2)
      )
    );
    setCurrentFamilia(null);
  };

  const selectFamilia = (a, category) => {
    setCurrentFamilia(a);
    if (a == currentFamilia) {
      setCurrentFamilia(null);
    }
    setSubClases(
      clases.filter(
        (clase) =>
          clase.code.toString().substring(0, 4) ==
          category.code.toString().substring(0, 4)
      )
    );
  };

  const selectClase = (category) => {
    if (selectedClases.indexOf(category) == -1) {
      setSelectedClases((clases) => [...clases, category]);
    } else {
      console.log("Category already selected", selectedClases);
    }
  };

  const deleteClase = (clase) => {
    if (selectedClases.indexOf(clase) != -1) {
      setSelectedClases(selectedClases.filter((clas) => clas != clase));
    }
  };

  const [keyWord, setKeyWord] = useState("");
  const [serachedItems, setSearchedItems] = useState([]);
  const searchKeyWord = () => {
    if (keyWord != "") {
      let itemsMatched = clases.filter((item) =>
        item.esp.toLowerCase().includes(keyWord)
      );
      setSearchedItems(itemsMatched);
    } else {
      setSearchedItems([]);
    }
  };

  return (
    <Fragment>
      <Container>
        <Row>
          <Col sm="6">
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <h4>{"Categorias Disponibles"}</h4>
              </div>
              <div style={{ width: "50%" }}>
                <FormGroup className="m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                  <div className="radio radio-primary">
                    <Input
                      id="radioinline1"
                      type="radio"
                      name="radio1"
                      value="option1"
                      defaultChecked
                      onClick={()=>filterSegmento("PRODUCTOS")}
                    />
                    <Label className="mb-0" for="radioinline1">
                      {"Productos"}
                    </Label>
                  </div>
                  <div className="radio radio-primary">
                    <Input
                      id="radioinline2"
                      type="radio"
                      name="radio1"
                      value="option1"
                      onClick={()=>filterSegmento("SERVICIOS")}
                    />
                    <Label className="mb-0" for="radioinline2">
                      {"Servicios"}
                    </Label>
                  </div>
                </FormGroup>
              </div>
            </div>

            <InputGroup>
              <Input
                type="text"
                class="form-control m-t-5 m-b-5"
                placeholder="Busqueda por producto o servico"
                value={keyWord}
                disabled={serachedItems.length == 0 ? false : true}
                onChange={(event) =>
                  setKeyWord(event.target.value.toLowerCase())
                }
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  {serachedItems.length == 0 ? (
                    <Button color="warning" onClick={searchKeyWord}>
                      {"Buscar"}
                    </Button>
                  ) : (
                    <Button
                      color="danger"
                      onClick={() => {
                        setKeyWord("");
                        setSearchedItems([]);
                      }}
                    >
                      {"Limpiar"}
                    </Button>
                  )}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>

            <br />

            <ScrollArea horizontal={false} vertical={true}>
              {serachedItems.length == 0 ? (
                <ul className="p-10" style={{ backgroundColor: "mintcream" }}>
                  {filteredSegmentos.map((segmento, i) => {
                    return (
                      <Fragment>
                        <li
                          className={`m-b-10 ${
                            currentSegmento == i ? "f-w-600" : ""
                          }`}
                          style={{ textAlign: "initial", cursor: "pointer" }}
                          key={`segmento_${i}_${segmento.code}`}
                          onClick={() => setSegmento(i, segmento.code)}
                        >
                          {currentSegmento == i ? (
                            <i className="fa fa-angle-right"></i>
                          ) : (
                            <i className="fa fa-angle-down"></i>
                          )}{" "}
                          &nbsp; {segmento.esp}
                          <hr />
                        </li>
                        {currentSegmento == i ? (
                          <Fragment>
                            <ul>
                              {subFamilias.map((subFamilia, a) => {
                                return (
                                  <Fragment>
                                    <li
                                      className={`m-l-10 m-b-10 ${
                                        currentFamilia == a ? "f-w-600" : ""
                                      }`}
                                      style={{
                                        textAlign: "initial",
                                        cursor: "pointer",
                                      }}
                                      key={`subFamilias_${a}_${subFamilia.code}`}
                                      onClick={() => {
                                        selectFamilia(a, subFamilia);
                                      }}
                                    >
                                      {currentFamilia == a ? (
                                        <i className="fa fa-angle-right"></i>
                                      ) : (
                                        <i className="fa fa-angle-down"></i>
                                      )}{" "}
                                      &nbsp;
                                      {subFamilia.esp}
                                      <hr />
                                    </li>
                                    {currentFamilia == a ? (
                                      <Fragment>
                                        <ul>
                                          {subClases.map((subClas, e) => {
                                            return (
                                              <li
                                                className="m-l-20 m-b-10"
                                                style={{
                                                  textAlign: "initial",
                                                  cursor: "pointer",
                                                }}
                                                key={`subClas_${e}`}
                                                onClick={() => {
                                                  selectClase(subClas);
                                                }}
                                              >
                                                {"-"}
                                                &nbsp; {subClas.esp}
                                                <hr />
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </Fragment>
                                );
                              })}
                            </ul>
                          </Fragment>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    );
                  })}
                </ul>
              ) : (
                <ul className="p-10" style={{ backgroundColor: "mintcream" }}>
                  {serachedItems.map((subClas, e) => {
                    return (
                      <li
                        className="m-l-20 m-b-10"
                        style={{
                          textAlign: "initial",
                          cursor: "pointer",
                        }}
                        key={`subClas_${e}`}
                        onClick={() => {
                          selectClase(subClas);
                        }}
                      >
                        {"-"}
                        &nbsp; {subClas.esp}
                        <hr />
                      </li>
                    );
                  })}
                </ul>
              )}
            </ScrollArea>
          </Col>

          <Col sm="6">
            <h4>{"Clases Seleccionadas "}</h4>
            <ul
              style={{ textAlign: "initial", backgroundColor: "mintcream" }}
              className="p-10"
            >
              {selectedClases.map((clase, i) => {
                return (
                  <li
                    className="m-b-5"
                    style={{ textAlign: "initial", cursor: "pointer" }}
                    key={`selectedClases_${i}_${clase.code}`}
                    onClick={() => deleteClase(clase)}
                  >
                    <Badge color="light">
                      <i className="fa fa fa-close fa-bg"></i> &nbsp;
                      <span className="f-12">{clase.esp}</span>
                    </Badge>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Samplepage;
